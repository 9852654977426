import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				PerfectCut Barbershop
			</title>
			<meta name={"description"} content={"Сделайте шаг в мир, где традиции встречаются с трендами, а каждая стрижка – символ стиля и изящества. В PerfectCut Barbershop мы верим, что отличная стрижка является краеугольным камнем прекрасного дня. Независимо от того, готовитесь ли вы к важному событию или просто хотите обновить свой образ, наша дверь открыта, чтобы предоставить вам непревзойденный опыт ухода."} />
			<meta property={"og:title"} content={"PerfectCut Barbershop"} />
			<meta property={"og:description"} content={"Сделайте шаг в мир, где традиции встречаются с трендами, а каждая стрижка – символ стиля и изящества. В PerfectCut Barbershop мы верим, что отличная стрижка является краеугольным камнем прекрасного дня. Независимо от того, готовитесь ли вы к важному событию или просто хотите обновить свой образ, наша дверь открыта, чтобы предоставить вам непревзойденный опыт ухода."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66701633fdcae00021e37692/images/1-3.jpg?v=2024-06-17T10:57:08.087Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66701633fdcae00021e37692/images/1-3.jpg?v=2024-06-17T10:57:08.087Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66701633fdcae00021e37692/images/1-3.jpg?v=2024-06-17T10:57:08.087Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66701633fdcae00021e37692/images/1-3.jpg?v=2024-06-17T10:57:08.087Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66701633fdcae00021e37692/images/1-3.jpg?v=2024-06-17T10:57:08.087Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66701633fdcae00021e37692/images/1-3.jpg?v=2024-06-17T10:57:08.087Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66701633fdcae00021e37692/images/1-3.jpg?v=2024-06-17T10:57:08.087Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header23>
			<Override slot="link" />
			<Override slot="box" />
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text1" />
		</Components.Header23>
		<Section padding="90px 0 100px 0" background="linear-gradient(180deg,--color-dark 0%,rgba(1, 1, 1, 0.6) 72.9%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66701633fdcae00021e37692/images/2-1.jpg?v=2024-06-17T10:57:08.075Z) center/cover no-repeat scroll padding-box" quarkly-title="Price-18">
			<Override slot="SectionContent" />
			<Text margin="0px 0px 3rem 0px" text-align="center" font="normal 500 46px/1.2 --fontFamily-sans" color="--light">
				Ознакомьтесь с нашими услугами
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="35px 0"
				margin="0px 0px 2rem 0px"
				align-items="center"
				justify-items="center"
				align-content="center"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="2rem 0px 10px 0px" color="--dark" text-align="center" font="--base">
								Классический крой и современный стиль Precision Cuts: Приспособлены к вашему личному стилю и чертам лица. Современный стиль: от фактурного вида до резких, чистых линий – достигните идеальной современной отделки.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="2rem 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--dark" text-align="center" font="--base">
								Стрижка и уход за бородой Подробная подстригание: Доведите свою бороду до совершенства с помощью наших тщательных услуг по подстриганию. Кондиционирующие процедуры: Сохраняйте свою бороду здоровой и мягкой с помощью наших специализированных продуктов.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="2rem 0px 10px 0px" color="--dark" text-align="center" font="--base">
								Специальные процедуры для волос Массаж кожи головы: Расслабьтесь с помощью лечебного массажа кожи головы, улучшающего здоровье волос. Глубокое кондиционирование: Восстановите жизненную силу с помощью процедур, питающих и омолаживающих ваши волосы.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="35px 0"
				align-content="center"
				align-items="center"
				justify-items="center"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="2rem 0px 10px 0px" color="--dark" text-align="center" font="--base">
								Пользовательские цветовые услуги Незначительные блики: Добавьте измерение с помощью индивидуальных бликов, которые подчеркивают ваш естественный цвет. Смелые изменения цвета: Сделайте заявление о новом, ярком цвете, адаптированном к вашим предпочтениям.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="2rem 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--dark" text-align="center" font="--base">
								Стили для особых случаев Подготовка к событию: Смотрите как можно лучше для любого события с помощью наших специализированных услуг по стилю. Индивидуальные консультации: Работайте с нашими стилистами, чтобы создать идеальный образ для любого случая.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="2rem 0px 10px 0px" color="--dark" text-align="center" font="--base">
								Вышеприведенный список – это лишь краткий обзор того, что мы предлагаем в PerfectCut Barbershop. Мы приглашаем вас посетить нас и ознакомиться с полным спектром наших услуг, разработанных в соответствии с вашими потребностями и предпочтениями в уходе. Независимо от того, хотите ли вы сохранить свой внешний вид или изменить его, наша дверь всегда открыта, чтобы предоставить вам самый высокий уровень обслуживания.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer1 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});